import { convertMinToMs } from "@equisafe-ui-vue/src/services/time";
export const prod = [
    "app.equisafe.io",
    "myclubdeal.equisafe.io",
    "kyoseil-am.equisafe.io",
    "phebe.equisafe.io",
    "mutualize.equisafe.io",
    "neotrees.equisafe.io",
    "magnitude-construction.equisafe.io",
    "arthaud.equisafe.io",
    "datafactory.equisafe.io",
    "stark-inc.equisafe.io",
    "my-seed-advisor.equisafe.io"
];
export const formation = [
    "app.dev.equisafe.io",
    "myclubdeal.dev.equisafe.io",
    "kyoseil-am.dev.equisafe.io",
    "phebe.dev.equisafe.io",
    "mutualize.dev.equisafe.io",
    "neotrees.dev.equisafe.io",
    "magnitude-construction.dev.equisafe.io",
    "arthaud.dev.equisafe.io",
    "datafactory.dev.equisafe.io",
    "stark-inc.dev.equisafe.io",
    "my-seed-advisor.dev.equisafe.io"
];

export const local = ["localhost", "127.0.0.1"];

export function isProd() {
    return prod.includes(window.location.hostname);
}

export function isFormation() {
    return formation.includes(window.location.hostname);
}

export function isLocal() {
    return local.includes(window.location.hostname);
}

export const API_PATH = "/api";
export const AUTH_PATH = "/auth";
export const ACTIVATE_DEBUGGER_EVENTS_VUE_JS = true;
export const PRODUCTION_ENVIRONMENT = isProd() || isFormation();
export const KYC_PROCESSING_TIMEOUT = !isProd() ? 120000 : 24000000;
export const KYC_INTERVAL = !isProd() ? 2000 : 20000;
export const LW_PROCESSING_TIMEOUT = !isProd() ? 120000 : 300000;
export const LW_INTERVAL = !isProd() ? 2000 : 4000;

export const MAX_SIZE_FILE = 9.5 * 1000 * 1000;
export const MAX_SIZE_IMG = 2 * 1000 * 1000;

export const YS_INTERVAL = !isProd() ? 2000 : 15000;
export const YS_TIMEOUT = !isProd() ? convertMinToMs(5) : convertMinToMs(20);

export const ADD_SEND_TOKEN_INTERVAL = !isProd() ? 10000 : convertMinToMs(1);
export const ADD_SEND_TOKEN_TIMEOUT = !isProd() ? convertMinToMs(10) : convertMinToMs(90);

export const DIGITALIZE_INTERVAL = !isProd() ? 5000 : convertMinToMs(0.5);
export const DIGITALIZE_TIMEOUT = !isProd() ? convertMinToMs(5) : convertMinToMs(60);

export const TX_EXPLORER_INTERVAL = !isProd() ? 15000 : convertMinToMs(0.5);
export const TX_EXPLORER_TIMEOUT = !isProd() ? convertMinToMs(2) : convertMinToMs(15);

export const INVESTOR_UPDATE_INTERVAL = !isProd() ? 5000 : convertMinToMs(1);
export const INVESTOR_UPDATE_TIMEOUT = !isProd() ? convertMinToMs(5) : convertMinToMs(30);

export const CESSION_INTERVAL = !isProd() ? 5000 : convertMinToMs(0.5);
export const CESSION_TIMEOUT = !isProd() ? convertMinToMs(5) : convertMinToMs(45);

export const NOTIFS_INTERVAL = !isProd() ? convertMinToMs(1) : convertMinToMs(1);
export const NOTIFS_TIMEOUT = !isProd() ? convertMinToMs(24 * 60) : convertMinToMs(24 * 60);

export const BLOCKCHAIN_LIST = {
    MAIN: {
        ID: 1,
        NAME: "Main network"
    },
    ROPSTEN: {
        ID: 3,
        NAME: "Ropsten network"
    },
    KOVAN: {
        ID: 42,
        NAME: "Kovan network"
    },
    GANACHE: {
        ID: 7777,
        NAME: "Ganache network"
    },
    KALEIDO: {
        ID: 229465855,
        NAME: "Kaleido network"
    },
    RINKEBY: {
        ID: 4,
        NAME: "Rinkeby network"
    }
};

export const CONFIRMATION_TRANSACTION_NUMBER = 3;
export const TRANSACTION_BLOCK_TIMEOUT = 42;
