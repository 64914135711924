import axios from "axios";
import Vue from "vue";
import autoBind from "auto-bind";

import store from "@/store/store.js";
import { getRootURL, getCookie, csrfSafeMethod } from "@/util";
import { i18n } from "@/services/plugins/i18n.js";
import isEmpty from "lodash/isEmpty";

export class APIService {
    constructor() {
        autoBind(this);
        this.endpoint = getRootURL();
        this.resetData();
    }

    resetData() {
        this.data = {};
        this.params = {};
        this.headers = {};
        this.method = "";
        this.url = "";
    }

    currentRoleId() {
        if (store.getters.currentRole && store.getters.currentRole.id)
            return store.getters.currentRole.id;
        return null;
    }

    setRequestData(method, url, data, params, responsetype = false) {
        this.method = method;
        this.url = url;
        this.data = data;
        this.params = params;

        if (store.getters.universe_id) this.headers["universe-id"] = store.getters.universe_id;

        if (method == "get" || method == "delete") {
            this.params = data;
            this.data = {};
        }
        if (
            (method === "post" || method === "patch") &&
            !(data instanceof URLSearchParams) &&
            !(data instanceof FormData) &&
            isEmpty(data)
        ) {
            console.warn(`request "${method}" on ${url} doesn\'t have data !`);
            this.data = {};
        }
        if (!csrfSafeMethod(method)) {
            const csrfToken = getCookie("csrftoken2");
            if (csrfToken) this.headers["X-CSRFToken"] = csrfToken;
            else console.error("no csrf cookie found");
        }
        const currentRoleId = this.currentRoleId();
        if (currentRoleId) this.headers["Current-Role-Id"] = currentRoleId;
        if (store.getters.useThroughRoles.useIt) {
            this.headers["Current-Role-Id"] = store.getters.useThroughRoles.currentRole;
            this.headers["Through-Roles"] = JSON.stringify([
                store.getters.useThroughRoles.throughRole
            ]);
        }
    }

    simpleRequest(method, url, params, other = {}) {
        return axios({
            method,
            url,
            params,
            ...other
        })
            .then(this.success.bind(this))
            .catch(this.error.bind(this));
    }

    requestArrayBuffer(method, url, data, params) {
        this.setRequestData(method, url, data, params);
        return axios({
            method: this.method,
            url: this.url,
            data: this.data,
            params: this.params,
            baseURL: this.endpoint,
            withCredentials: true,
            responseType: "arraybuffer",
            headers: this.headers
        })
            .then(this.success.bind(this))
            .catch(this.error.bind(this));
    }

    request(method, url, data, params, responseType = "json") {
        // console.log("REQUEST:", method, url);
        this.setRequestData(method, url, data, params);
        return axios({
            method: this.method,
            url: this.url,
            data: this.data,
            params: this.params,
            paramsSerializer: params => {
                return Object.entries(params)
                    .map(([key, value]) => `${key}=${value}`)
                    .join("&");
            },
            headers: this.headers,
            responseType: responseType,
            baseURL: this.endpoint,
            withCredentials: true
        })
            .then(this.success.bind(this))
            .catch(this.error.bind(this));
    }

    requestFile(url) {
        return this.request("get", url, null, {}, "blob");
    }

    success(response) {
        this.resetData();
        if (store.getters.isNetworkFailure)
            window.app.$store.commit("SET_IS_NETWORK_FAILURE", false);
        return response;
    }

    error(error) {
        this.resetData();
        const isNetworkError = error.message === "Network Error" && !store.getters.isNetworkFailure;
        const isNotAuthorizedError = error.response && [403, 401].includes(error.response.status);
        // handle Network Error, meaning backend is offline, redirect user to auth in that case
        if (isNetworkError || isNotAuthorizedError) {
            const isAuthError = error.config.url === "/api/entity/me/";
            if (isAuthError || isNetworkError) {
                // window.app.$store.commit("RESET_STORE_ENTITIES");
                // window.app.$store.commit("RESET_STORE_ROLES");
                window.app.$store.commit(
                    isAuthError ? "SET_IS_NOT_AUTHENTICATED" : "SET_IS_NETWORK_FAILURE",
                    true
                );
                const route = window.app.$route;
                if (!route.path.includes("auth") && route.path !== "/") {
                    window.app.$ui.error(
                        isAuthError ? i18n.t("error.auth-error") : i18n.t("error.network-error")
                    );
                    if (isAuthError) {
                        window.app.$router.push({
                            name: "auth"
                        });
                    }
                }
                return Promise.reject(error.response);
            } else if (isNotAuthorizedError) {
                // if error is 403 or 401, try to check if session is authenticated. If it's the case it should not go further.
                // in the case it's not authenticated it will fail and redirect user to login page
                // through isAuthError
                store.dispatch("getMyEntity");
            }
        }
        return Promise.reject(error.response);
    }

    /* ----- Web Socket ------- */
    getWebSocketPublic() {
        return this.request("get", "/ws/public/");
    }

    getWebSocketPrivate() {
        return this.request("get", "/ws/private/");
    }
    /* ----- Auth ------- */

    getCSRFToken() {
        return this.request("get", "/auth/login/");
    }

    verifyRegistration(data) {
        return this.request("post", "/auth/verify-registration/", data);
    }

    forgotPassword(data) {
        return this.request("post", "/auth/send-reset-password-link/", data);
    }

    forgotPasswordSubmit(data) {
        return this.request("post", "/auth/reset-password/", data);
    }

    changePassword(data) {
        return this.request("post", "/auth/change-password/", data);
    }

    register(data) {
        return this.request("post", "/auth/register/", data);
    }

    login(data) {
        return this.request("post", "/auth/login/", data);
    }

    resendInvitationEmailKyoseil(entityId) {
        return this.request("get", `api/entity/${entityId}/resend_email_invitation/`);
    }

    getCGPClients() {
        return this.request("get", "/api/cgp-clients/");
    }

    getClients(cgpId) {
        return this.request("get", `/api/cgps/${cgpId}/clients/`);
    }

    getBusinessProviders() {
        return this.request("get", "/api/business-providers/");
    }

    getStatsAllCGP() {
        return this.request("get", "api/cgps/summary/");
    }

    getStatsAllBP() {
        return this.request("get", "api/business_providers/summary/");
    }

    getStatsCGP(cgpId) {
        return this.request("get", `api/cgps/${cgpId}/summary/`);
    }

    getStatsAllClients() {
        return this.request("get", "api/cgps/clients/summary/");
    }

    getStatsBP(bpId) {
        return this.request("get", `api/business_providers/${bpId}/summary/`);
    }

    getEntityCGPInfo(cgpId) {
        return this.request("get", `api/cgps/${cgpId}/entity/`);
    }

    getEntityBPInfo(bpId) {
        return this.request("get", `api/business_providers/${bpId}/entity/`);
    }

    getBusinessProviderClient(bpId) {
        return this.request("get", `api/business_providers/${bpId}/`);
    }

    getDealClientFromAdmin(clientId) {
        return this.request("get", `/api/cgps/clients/${clientId}/`);
    }

    getAllClients() {
        return this.request("get", "api/cgps/clients/");
    }

    getClientDeals(cgpId, clientId) {
        return this.request("get", `/api/cgps/${cgpId}/clients/${clientId}/`);
    }

    getInvestorInfos(investorId) {
        return this.request("get", `/api/affi-investors/${investorId}/`);
    }

    createBusinessProvider(dataBp) {
        /*
        # person
        data = {
            "first_name": "Business",
            "last_name": "Provider",
            "email": "business_1@provider.com"
        }
         # company
        data = {
            "is_company": True,
            "first_name": "Business",
            "last_name": "Provider",
            "email": "business_2@provider.com",
            "company_name": "Business Provider Inc.",
            "company_idcode": "ABC123",
        }
        */
        return this.request("post", `/api/business_providers/add/`, dataBp);
    }

    createCGP(dataCGP) {
        return this.request("post", `/api/cgps/add/`, dataCGP);
    }

    createClient(dataClient) {
        return this.request("post", `/api/cgps/clients/add/`, dataClient);
    }

    patchEntityAttributeStatus(id, status) {
        return this.request("patch", `/api/entity_attributes/${id}/`, {
            validation_status: status // Accepted, Rejected, TBD
        });
    }

    getUniverseCGPs() {
        return this.request("get", "/api/cgps/");
    }

    deleteInvestorFromCgp(cgpId, entityId) {
        return this.request("delete", `api/cgps/${cgpId}/clients/${entityId}/delete/`);
    }

    logout() {
        window.app.$store.commit("SET_IS_AUTHENTICATED", false);
        window.app.$store.commit("RESET_STORE_ENTITIES");
        location.replace(`${getRootURL()}auth/logout/`);
    }

    getTwoFA() {
        return this.request("get", "/qrcode/");
    }

    setTwoFA(data) {
        return this.request("post", "/qrcode/", data);
    }

    resendEmailVerificationLink(data) {
        return this.request("post", "/auth/resend_email_verification_link/", data);
    }

    /* ----- AG ------- */
    getAg(id = null, filters = {}) {
        let endpoint = "/api/ag/";
        if (id) {
            endpoint = endpoint + id.toString() + "/";
        }
        return this.request("get", endpoint, filters);
    }
    getAgMembers(id) {
        let endpoint = "/api/ag/" + id.toString() + "/members/";
        return this.request("get", endpoint);
    }
    agSendPresence(id, data) {
        /*
        PATCH
        id: id of AOGMember
        answer: PRESENCE_STATE backend
        data: if represented, doc file + represented entity
        */
        return this.request("patch", "/api/aogm/" + id + "/", data);
    }

    /* ----- Entity ------- */

    me() {
        return this.request("get", "/api/entity/me/");
    }

    entityInvite(data) {
        return this.request("post", "/api/entity/new/", data);
    }

    entityInviteRole(data) {
        return this.request("post", "/api/entity/invite_role/", data);
    }

    getEntitiesInvite(data) {
        return this.request("get", "/api/entity/listinvite/", data);
    }

    getEntity(id) {
        return this.request("get", `/api/entity/${id}/`);
    }

    getEntityInfos(id, affiliation_mode = false) {
        let url = `/api/entity/${id}/infos/`;
        if (affiliation_mode) {
            url += "?affiliate=true";
        }
        return this.request("get", url);
    }

    getNotifications(queries) {
        return this.request("get", `/api/notification/`, queries);
    }

    getNotification(id) {
        return this.request("get", `/api/notification/${id}/`);
    }
    getNotificationNumActive() {
        return this.request("get", `/api/notification/num_active/`);
    }

    updateNotifications(data) {
        return this.request("patch", `/api/notification/bulk_update/`, data);
    }

    // deleteNotifications(entityId, notifications) {
    //   return this.request("delete", `/api/notification/`, {
    //     entity_represented_id: entityId,
    //     notifications
    //   });
    // }

    /**
     * List all roles (my roles + rolesOfMyTeam)
     * @param {Object with `user_id` and/or `entity_represented_id`}
     * @return Promise with role list in params
     *
     * if no params are given, returns all the roles.
     * if user_id is given, returns myRoles
     * if entity_represented_id is given, returns teamMember roles
     * if user_id && entity_represented_id are given, returns me
     *
     * @example
     *
     *     roleList({user_id: `${user_id}`})
     */

    roleList(params) {
        return this.request("get", "/api/role/", params);
    }

    getCgpRoleFromInvestor(id) {
        return this.request("get", `/api/role/cgp-role-from-investor/${id}/`);
    }
    getRole(id) {
        return this.request("get", `/api/role/${id}/`);
    }

    roleUpdate(id, data) {
        return this.request("patch", `/api/role/${id}/`, data);
    }

    roleDelete(id) {
        return this.request("delete", `/api/role/${id}/`);
    }

    createEntity(data) {
        return this.request("post", "/api/entity/", data);
    }

    createNeotrees(entityId, data) {
        return this.request("patch", `/api/entity/${entityId}/create_neotrees/`, data);
    }

    updateEntity(entityId, data) {
        return this.request("patch", `/api/entity/${entityId}/`, data);
    }

    updateEntityCgp(entityId, data) {
        return this.request("patch", `/api/entity/${entityId}/update_cgp/`, data);
    }

    startKycKyoseilDirectInvestSignature(entityId, data) {
        return this.request(
            "patch",
            `/api/entity/${entityId}/start_kyc_kyoseil_direct_invest_signature/`,
            data
        );
    }

    populateEntity(entityId, siren) {
        return this.request("get", `/api/entity/${entityId}/populate/?siren=${siren}`);
    }

    verifyWallet(entityId) {
        return this.request("post", `/api/entity/${entityId}/kyc/`, {
            final_step: false
        });
    }

    verifyEntity(entityId) {
        return this.request("post", `/api/entity/${entityId}/kyc/`, {
            final_step: true
        });
    }

    getUniverses(id) {
        return this.request("get", `/api/univers/`, {
            entity_id: id
        });
    }

    getUniverse(id) {
        return this.request("get", `/api/univers/${id}/`);
    }

    getCorners() {
        return this.request("get", "/api/corners/");
    }

    getCorner(name) {
        return this.request("get", `/api/corners/${name}/get_corner/`);
    }

    addCorners(corners) {
        return this.request("post", `/api/corners/add_corners/`, {
            corners
        });
    }

    getCornerProject(name) {
        return this.request("get", `/api/capital-increase-corners/`, {
            corner: name,
            cip: true
        });
    }

    getCornerOrders(data) {
        return this.request("get", `/api/capital-increase-corders/`, data);
    }

    /* ----- Blockchain calls ------- */

    reloadTransactions() {
        return this.request("post", "/api/blockchain-account/reload_transactions/");
    }

    canReloadTransactions() {
        return this.request("get", "/api/blockchain-account/can_reload/");
    }

    getBlockchainList() {
        return this.request("get", "/api/blockchain/");
    }

    getBlockchainizeState() {
        return this.request("get", "/api/issuing-entity/blockchainize/");
    }

    deployIssuingEntity() {
        return this.request("post", "/api/issuing-entity/deploy/", {});
    }

    redeployContract(contract) {
        return this.request("put", contract.url, {
            error: "",
            state: "deploy"
        });
    }

    // country & region

    getRegions(data) {
        return this.request("get", "/api/region/", data);
    }

    getCountries(
        data = {
            ordering: "name"
        }
    ) {
        return this.request("get", "/api/country/", data);
    }

    setCountries(data) {
        return this.request("post", "/api/set-countries/", data);
    }

    removeCountries(data) {
        return this.request("post", "/api/remove-countries/", data);
    }

    getCountriesSet(id) {
        return this.request("get", `/api/set-countries/${id}/`);
    }

    getCountryConstraintList(data) {
        return this.request("get", "/api/country-constraint/", data);
    }

    setInvestorLimits(data) {
        return this.request("post", "/api/set-limit/", data);
    }

    getInvestorLimits(id) {
        return this.request("get", `/api/set-limit/${id}/`);
    }

    setGlobalRestriction(data) {
        return this.request("post", "/api/set-global-restriction/", data);
    }

    deploySecurityToken(data) {
        return this.request("post", "/api/shareledger/", data);
    }

    updateInvestor(data) {
        return this.request("post", "/api/investor-update/", data);
    }

    getTransactions(data) {
        return this.request("get", "/api/transaction/", data);
    }

    getTransaction(id) {
        return this.request("get", `/api/transaction/${id}/`);
    }

    getTransactionExplorer(id) {
        return this.request("get", `/api/transaction/${id}/explorer_api/`);
    }

    getTransfer(id) {
        return this.request("get", `/api/wire/${id}/`);
    }

    getValidationOrder(id) {
        return this.request("get", `/api/validation-order/${id}/`);
    }

    validateOrder(id) {
        return this.request("post", `/api/validation-order/${id}/accept/`);
    }

    refuseOrder(id) {
        return this.request("post", `/api/validation-order/${id}/refuse/`);
    }

    getTransferDocs(id) {
        return this.request("get", `/api/wire/${id}/docs/`);
    }

    getTransfers(data) {
        return this.request("get", "/api/wire/", data);
    }

    getDividend(data) {
        return this.request("get", "/api/wire/dividend/", data);
    }

    createTransfer(data) {
        return this.request("post", "/api/wire/", data);
    }

    refuseTransfer(id) {
        return this.request("patch", `/api/wire/${id}/`, {
            lemonway_status: "refused"
        });
    }

    acceptTransfer(id) {
        return this.request("patch", `/api/wire/${id}/`, {
            lemonway_status: "pending"
        });
    }

    // POST CSV: RETURN FORMATED AND VALIDATE ENTRY OF CSV
    parseCSVTransaction(data) {
        return this.request("post", "/api/wire/asset_operation_multiple/", data);
    }
    confirmCSVTransaction(data) {
        return this.request("post", "/api/wire/asset_operation_multiple_creation/", data);
    }

    getTransaction(id) {
        return this.request("get", `/api/transaction/${id}/`);
    }

    getTransactionByHash(transactionHash) {
        return this.request("get", "/api/transaction/", {
            txhash: transactionHash
        });
    }

    companyShareledgerList(companyId) {
        return this.request("get", `/api/shareledger/?company_id=${companyId}`);
    }

    // TODO, not used so remove ?
    getShareledgerList() {
        return this.request("get", `/api/shareledger/`);
    }

    // TODO, not used so remove ?
    getShareholdersList() {
        return this.request("get", `/api/shareholders/`);
    }

    getShareledger(id) {
        return this.request("get", `/api/shareledger/${id}/`);
    }

    createShareledger(data) {
        return this.request("post", "/api/shareledger/", data);
    }

    updateShareledger(shareledgerId, data) {
        return this.request("patch", `/api/shareledger/${shareledgerId}/`, data);
    }

    readInvestorWhiteList(data) {
        return this.request("get", "/api/investor-whitelist/", data);
    }

    getInvestor(id) {
        return this.request("get", `/api/investor-whitelist/${id}/`);
    }

    downloadPropertyProof(investorId, shareledgerId) {
        return this.request("get", `/api/investor-whitelist/${investorId}/property_proof/`, {
            shareledgerId
        });
    }

    getCaptable(id) {
        return this.request("get", `/api/investor/captable/`);
    }

    deleteInvestor(id) {
        return this.request("delete", `/api/investor/${id}/`);
    }

    createInvestor(data) {
        return this.request("post", "/api/investors/", data);
    }

    // Add API call for investor group type
    getInvestorOptions() {
        return this.request("options", "api/investor/");
    }

    // Be warned that updateInvestor also exists, which hits /api/investor-update/
    patchInvestor(id, data) {
        return this.request("patch", `api/investor/${id}/`, data);
    }

    inviteMultipleInvestors(data) {
        return this.request("post", "api/investor/invite_multiple/", data);
    }

    downloadMultiplePayment(data) {
        return this.request("post", "api/share/asset_operation_multiple/", data);
    }

    inviteMultipleInvestorsWithAssetmovements(data) {
        return this.request("post", "api/investor/invite_multiple_with_assetmovements/", data);
    }

    /**
     * @param {Object} data - possible filter {shareledger_id : '', operation : ''}
     */
    readAssetMovementList(data) {
        return this.request("get", "/api/asset-movement/", data);
    }

    readTinyAssetMovementList(data) {
        return this.request("get", "/api/tiny-asset-movement/", data);
    }

    readAssetMovement(id) {
        return this.request("get", `/api/asset-movement/${id}/`);
    }

    blockchainInscriptionOfInvestor(data) {
        return this.request("post", "/api/asset-movement/invite/", data);
    }

    createCession(data) {
        return this.request("post", "/api/asset-movement/", data);
    }

    deleteAssetMovement(id) {
        return this.request("delete", `/api/asset-movement/${id}/`);
    }

    updateAssetMovement(id, data) {
        return this.request("patch", `/api/asset-movement/${id}/`, data);
    }

    createProcedureDoc(data) {
        return this.request("post", "/api/procedure-document/", data);
    }

    updateProcedureDoc(id, data) {
        return this.request("patch", `/api/procedure-document/${id}/`, data);
    }

    deleteProcedureDoc(id) {
        return this.request("delete", `/api/procedure-document/${id}/`);
    }

    setProcedureBox(data) {
        return this.request("put", "/api/procedure-document-box/set/", data);
    }

    getVariableBoxes(procedureId) {
        return this.request("get", "/api/variable-box/", {
            signature_procedure_id: procedureId
        });
    }

    getProcedureBoxes(data) {
        return this.request("get", "/api/procedure-document-box/", data);
    }

    /*
        Par default, on fetch le max : cas yousign
        Espace signature : c'est géré
        Le reste : ancien système avec le store
    */
    getProcedures(data = { page_size: 1000 }) {
        return this.request("get", "/api/procedure/", data);
    }

    getProcedure(id, localEnv) {
        let query = localEnv === true ? "?local=1" : "";
        return this.request("get", `/api/procedure/${id}/${query}`);
    }

    deleteProcedure(id) {
        return this.request("delete", `/api/procedure/${id}/`);
    }

    createProcedure(name, description, advanced, template_type = null) {
        return this.request("post", "/api/procedure/", {
            name,
            description,
            advanced,
            template_type
        });
    }

    updateProcedure(id, data) {
        return this.request("patch", `/api/procedure/${id}/`, data);
    }

    listSigners(data) {
        return this.request("get", "/api/signature-signer/", data);
    }

    addSigner(procedureId, entityId) {
        return this.request("post", "/api/signature-signer/", {
            signature_procedure_id: procedureId,
            entity_id: entityId
        });
    }

    deleteSigner(signerId) {
        return this.request("delete", `/api/signature-signer/${signerId}/`);
    }

    getSignature(id) {
        return this.request("get", `/api/signature/${id}/`);
    }

    getSignatures(data) {
        return this.request("get", `/api/signature/`, data);
    }

    getPreview(procedureId, data) {
        return this.requestArrayBuffer("post", `/api/procedure/${procedureId}/preview/`, data);
    }

    // TODO put correct route + change to assign all in procedure
    populateSigners(procedureId, signers) {
        return this.request("put", "/api/signature-signer/set/", {
            signature_procedure_id: procedureId,
            signers
        });
    }

    setSignersIntoDoc(procedure_document_id, procedure_document_signers) {
        return this.request("put", "/api/procedure-document-signer/set/", {
            procedure_document_id,
            procedure_document_signers
        });
    }

    async getProcedurePreview(procedureId, data) {
        try {
            const res = await this.getPreview(procedureId, data);
            const blob = new Blob([res.data], {
                    type: "application/pdf"
                }),
                url = window.URL.createObjectURL(blob);
            return url;
        } catch (e) {
            throw e;
        }
    }
    // todo: move to new signature service
    b64DecodeUnicode(str) {
        // Going backwards: from bytestream, to percent-encoding, to original string.
        return decodeURIComponent(
            atob(str)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
        );
    }

    startProcedure(id) {
        return this.request("patch", `/api/procedure/${id}/start/`);
    }

    async getSignatureMemberProof(signer) {
        try {
            const res = await this.getProcedureMemberProof(signer.id);
            const proofFileContent = res.data;
            // code to download proof as a .txt file

            // const proofFileContent = this.b64DecodeUnicode(res.data);
            // const blob = new Blob([proofFileContent], {
            //   type: "application/pdf",
            // });
            // saveAs(blob, `proof_file_member_${signer.id}.pdf`);

            const linkSource = `data:application/pdf;base64,${proofFileContent}`;
            const downloadLink = document.createElement("a");
            const fileName = `proof_file_member_${signer.id}.pdf`;
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
            return;
        } catch (err) {
            throw err;
        }
    }

    getProcedureMemberProof(signerid) {
        return this.request("get", `/api/signature-signer/${signerid}/member_proof/`);
    }

    // getProcedureProof(id, data) {
    //   return this.request("get", `/api/signature/${id}/procedure_proof/`, data);
    // }`

    invite(id) {
        return this.request("post", `/api/investor/${id}/sendInvitation/`);
    }

    readBalanceList(data) {
        return this.request("get", "/api/balance/", data);
    }

    getBalancePortfolio(data) {
        return this.request("get", "/api/balance/portfolio/", data);
    }

    getValuation(data) {
        return this.request("get", "/api/balance/valuation/", data);
    }

    getTotalAssetOwned(data) {
        return this.request("get", "/api/balance/total_asset_company/", data);
    }

    // capital increase

    createCapitalIncrease(data) {
        return this.request("post", "/api/capital-increase/", data);
    }

    updateCapitalIncrease(id, data) {
        return this.request("patch", `/api/capital-increase/${id}/`, data);
    }

    deleteCapitalIncrease(id) {
        return this.request("delete", `/api/capital-increase/${id}/`);
    }

    importCapitalIncrease(data) {
        return this.request("post", `/api/capital-increase/import_capinc/`, data);
    }

    inviteCapitalIncrease(data) {
        return this.request("post", `/api/capital-increase/invite/`, data);
    }

    getCapitalIncrease(id, data) {
        return this.request("get", `/api/capital-increase/${id}/`, data);
    }

    getCapitalIncreases(data) {
        return this.request("get", `/api/capital-increase/`, data);
    }

    getTinyCapitalIncreases(data) {
        return this.request("get", `/api/tiny-capital-increase/`, data);
    }

    testSignature(id) {
        return this.request("post", `/api/capital-increase/${id}/test_signature/`);
    }

    createCapitalIncreaseMember(data) {
        return this.request("post", "/api/capital-increase-member/", data);
    }

    updateCapitalIncreaseMember(id, data) {
        return this.request("patch", `/api/capital-increase-member/${id}/`, data);
    }

    deleteCapitalIncreaseMember(id) {
        return this.request("delete", `/api/capital-increase-member/${id}/`);
    }

    createCapitalIncreaseDoc(data) {
        return this.request("post", "/api/capital-increase-document/", data);
    }

    updateCapitalIncreaseDoc(id, data) {
        return this.request("patch", `/api/capital-increase-document/${id}/`, data);
    }

    deleteCapitalIncreaseDoc(id) {
        return this.request("delete", `/api/capital-increase-document/${id}/`);
    }

    getPendingOrdersLength(data) {
        return this.request("get", "/api/capital-increase-order/get_pending_orders/", data);
    }

    createOrder(data) {
        return this.request("post", "/api/capital-increase-order/", data);
    }

    setCapincOrderPaymentData(orderId, data) {
        return this.request(
            "post",
            `/api/capital-increase-order/${orderId}/set_payment_data/`,
            data
        );
    }

    setCapincOrderTaxArrangement(orderId, data) {
        return this.request(
            "post",
            `/api/capital-increase-order/${orderId}/set_tax_arrangement/`,
            data
        );
    }

    createCapincOrderSignatureProcedure(orderId, data) {
        return this.request(
            "post",
            `/api/capital-increase-order/${orderId}/create_signature_procedure/`,
            data
        );
    }
    setCapincOrderSubForm(orderId, data) {
        return this.request("post", `/api/capital-increase-order/${orderId}/set_sub_form/`, data);
    }

    deleteCapincOrder(orderId, data) {
        return this.request("delete", `/api/capital-increase-order/${orderId}/`, data);
    }

    signCapincOrder(orderId, capincId) {
        const data = {
            capinc_id: capincId
        };
        return this.request("post", `/api/capital-increase-order/${orderId}/sign/`, data);
    }

    sequestreCapincOrder(orderId, capincId) {
        const data = {
            capinc_id: capincId
        };
        return this.request("post", `/api/capital-increase-order/${orderId}/sequestre/`, data);
    }

    validateCapincOrder(orderId, capincId) {
        const data = {
            capinc_id: capincId
        };
        return this.request("post", `/api/capital-increase-order/${orderId}/validate/`, data);
    }

    refuseCapincOrder(orderId, capincId) {
        const data = {
            capinc_id: capincId
        };
        return this.request("patch", `/api/capital-increase-order/${orderId}/refuse/`, data);
    }

    getOrder(orderId) {
        return this.request("get", `/api/capital-increase-order/${orderId}/`);
    }

    getOrders(data) {
        return this.request("get", `/api/capital-increase-order/`, data);
    }

    getAdminOrders(data) {
        return this.request("get", `/api/capital-increase-order/admin/`, data);
    }

    updateOrder(orderId, data) {
        return this.request("patch", `/api/capital-increase-order/${orderId}/`, data);
    }

    cancelOrder(orderId, data) {
        return this.request("patch", `/api/capital-increase-order/${orderId}/user_cancel/`, data);
    }

    setVisibleBy(data) {
        return this.request("post", `/api/capital-increase-corners/${data}/set_visible_by/`);
    }

    getDocsPeaOrder(orderId) {
        return this.request("get", `/api/capital-increase-order/${orderId}/get_pea_docs/`);
    }

    setBankPeaOrder(orderId, data) {
        return this.request("patch", `/api/capital-increase-order/${orderId}/set_bank_pea/`, data);
    }

    sendMailPeaOrder(orderId, data) {
        return this.request("patch", `/api/capital-increase-order/${orderId}/send_mail_pea/`, data);
    }

    /* ----- Wallet ------- */
    createMoneyIn(amount) {
        return this.request("post", "/api/moneyin/", {
            amount
        });
    }

    createMoneyInRedirection(amount, redirection) {
        return this.request("post", `/api/moneyin/?route_redirection=${redirection}`, {
            amount
        });
    }

    createMoneyInCBRedirection(data, redirection) {
        return this.request("post", `/api/moneyin/?route_redirection=${redirection}`, data);
    }

    createMoneyOut(amount) {
        return this.request("post", "/api/moneyout/", {
            amount
        });
    }

    getMoneyInFees() {
        return this.request("get", "/api/fees/calculate_fees_money_in/");
    }

    getMoneyOutFees() {
        return this.request("get", "/api/fees/calculate_fees_money_out/");
    }

    getMovement(id) {
        return this.request("get", `/api/movement/${id}/`);
    }

    downloadMovement(movementId) {
        return this.request("get", `/api/movement/${movementId}/download/`);
    }

    getBill(id) {
        return this.request("get", `/api/factures/${id}/`);
    }

    getMovements() {
        return this.request("get", `/api/movement/`);
    }

    getSequestreMovements() {
        return this.request("get", `/api/internal-cash-movement/`);
    }

    getMyBalance() {
        return this.request("get", `/api/movement/balance/`);
    }

    getMySequestre() {
        return this.request("get", `/api/movement/sequestre/`);
    }

    createOperation(data) {
        return this.request("post", `/api/operation/`, data);
    }

    getMyVIBAN(entityId) {
        return this.request("get", `/api/entity/${entityId}/viban/`);
    }

    getMyIBANs() {
        return this.request("get", `/api/iban/`);
    }

    updateIBAN(id, data) {
        return this.request("patch", `/api/iban/${id}/`, data);
    }

    getMyIBANSepa(id) {
        return this.request("get", `/api/ibansepa/${id}/`);
    }

    getMyIBANExt(id) {
        return this.request("get", `/api/ibanext/${id}/`);
    }

    createIBANSepa(data) {
        return this.request("post", `/api/ibansepa/`, data);
    }

    createIBANExt(data) {
        return this.request("post", `/api/ibanext/`, data);
    }

    /* ------ PSFP form ------ */
    getPsfpForm(id = null) {
        if (id) {
            return this.request("get", `/api/psfp-form/${id}/`);
        } else {
            return this.request("get", `/api/psfp-form/`);
        }
    }
    getPsfpCompleted(id) {
        return this.request("get", `/api/psfp-form/${id}/is_completed/`);
    }
    createPsfpForm(data) {
        return this.request("post", `/api/psfp-form/`, data);
    }
    updatePsfpForm(id, data) {
        return this.request("patch", `/api/psfp-form/${id}/`, data);
    }

    /* ------ Quiz ------ */
    getCipPersonalForm() {
        return this.request("get", `/api/cip-personal-form/`);
    }
    createCipPersonalForm(data) {
        return this.request("post", `/api/cip-personal-form/`, data);
    }
    updateCipPersonalForm(id, data) {
        return this.request("patch", `/api/cip-personal-form/${id}/`, data);
    }
    createCipQuizForm(data) {
        return this.request("post", `/api/cip-quiz-form/`, data);
    }
    updateCipQuizForm(id, data) {
        return this.request("patch", `/api/cip-quiz-form/${id}/`, data);
    }

    /* EQS_AG */
    getPolls(data) {
        return this.request("get", `/api/poll/`, data);
    }
    createPoll(data) {
        return this.request("post", `/api/poll/`, data);
    }
    updatePoll(id, data) {
        return this.request("patch", `/api/poll/${id}/`, data);
    }
    deletePoll(id) {
        return this.request("delete", `/api/poll/${id}/`);
    }
    getChoice(id, data) {
        return this.request("get", `/api/choice/${id}/`, data);
    }
    createChoice(data) {
        return this.request("post", `/api/choice/`, data);
    }
    updateChoice(id, data) {
        return this.request("post", `/api/choice/${id}/`, data);
    }
    deleteChoice(id) {
        return this.request("delete", `/api/choice/${id}/`);
    }
    createVote(data) {
        return this.request("post", `/api/vote/`, data);
    }
    updateVote(id, data) {
        return this.request("patch", `/api/vote/${id}/`, data);
    }

    getConsultations(data) {
        return this.request("get", `/api/consultation/`, data);
    }
    createConsultation(data) {
        return this.request("post", `/api/consultation/`, data);
    }
    deleteConsultation(id) {
        return this.request("delete", `/api/consultation/${id}/`);
    }
    updateConsultation(id, data) {
        return this.request("patch", `/api/consultation/${id}/`, data);
    }
    // representative
    getRepresentatives(entityId) {
        return this.request("get", `/api/governance/representative/`, {
            entity_represented_id: entityId
        });
    }
    createRepresentative(representative) {
        return this.request("post", `/api/governance/representative/`, representative);
    }
    updateRepresentative(representativeId, representative) {
        return this.request(
            "patch",
            `/api/governance/representative/${representativeId}/`,
            representative
        );
    }
    deleteRepresentative(representativeId) {
        return this.request("delete", `/api/governance/representative/${representativeId}/`);
    }
    // organ
    getOrgans(entityId) {
        return this.request("get", `/api/governance/organ/`, {
            entity_represented_id: entityId
        });
    }
    createOrgan(organ) {
        return this.request("post", `/api/governance/organ/`, organ);
    }
    updateOrgan(organId, organ) {
        return this.request("patch", `/api/governance/organ/${organId}/`, organ);
    }
    deleteOrgan(organId) {
        return this.request("delete", `/api/governance/organ/${organId}/`);
    }

    //organ-member
    deleteOrganMember(url) {
        return this.request("delete", url);
    }

    // decision
    getDecisions(entityId) {
        return this.request("get", `/api/governance/decision/`, {
            entity_represented_id: entityId
        });
    }
    getDecision(decisionId) {
        return this.request("get", `/api/governance/decision/${decisionId}/`);
    }
    createDecision(decision) {
        return this.request("post", `/api/governance/decision/`, decision);
    }
    updateDecision(decisionId, decision) {
        return this.request("patch", `/api/governance/decision/${decisionId}/`, decision);
    }
    deleteDecision(decisionId) {
        return this.request("delete", `/api/governance/decision/${decisionId}/`);
    }
    lockDecision(decisionId) {
        return this.request("patch", `/api/governance/decision/${decisionId}/lock/`);
    }

    /* ---------------- */

    getEntityVerificationResult(entityId) {
        return this.request("get", `/api/entity/${entityId}/kyc/`);
    }

    /* Documents */
    getDocuments(data) {
        return this.request("get", "/api/document/", data);
    }

    getDocument(id) {
        return this.request("get", `/api/document/${id}/`);
    }

    postDocument(data) {
        return this.request("post", "/api/document/", data);
    }

    patchDocument(id, data) {
        return this.request("patch", `/api/document/${id}/`, data);
    }

    deleteDocument(id) {
        return this.request("delete", `/api/document/${id}/`);
    }
    /* ---------------- */

    /* Language */

    async getEntityOptions() {
        return this.request("options", "/api/entity/");
    }

    async setLanguage(lang) {
        // ex: URL pour activer le Francais: /language/?code=fr
        return this.request("get", `/language/`, {
            code: lang
        });
    }

    /* ----------------- */

    /* INVITATION */

    // Company invite by investor
    companyInviteByInvestor(data) {
        return this.request("post", "/api/balance/invite/", data);
    }

    /* ---------------- */

    async withPolling(callback, interval) {
        const data = await callback();

        // If no polling interval is given we
        // return a regular object with the data.
        if (!interval)
            return {
                data
            };

        // Otherwise, we create a new `Vue.observable`
        // instance and refetch the data according to
        // the specified polling interval.
        const observableData = Vue.observable({
            data
        });

        const poll = () => {
            setTimeout(async () => {
                observableData.data = {
                    ...(await callback())
                };
                poll();
            }, interval);
        };
        poll();

        return observableData;
    }

    passwordReset(email) {
        return this.request("post", "/auth/send-reset-password-link/", {
            email
        });
    }

    del(url) {
        return this.request("delete", url);
    }

    //COMPANY ANALYTICS
    getCompanyAnalytics(type, entity) {
        const params = {
            value_type: type == "nav" ? 2 : 1,
            entity_id: !!entity ? entity.id : null
        };
        return this.request("get", "/api/company-analytics/", params);
    }

    getPrices(company) {
        return this.request("get", `/api/share-price/?company_id=${company}`);
    }

    async getLastCompanyAnalytics(type, entity) {
        const analytics = await this.getCompanyAnalytics(type, entity);
        return analytics.data.length ? analytics.data[0] : null;
    }

    createCompanyAnalytics(data, type) {
        if (type == "nav") {
            data.value_type = 2;
        } else if (type == "marketValue") {
            data.value_type = 1;
        }
        return this.request("post", "/api/company-analytics/", data);
    }

    updateCompanyAnalytics(data) {
        return this.request("patch", `/api/company-analytics/${data.id}/`, data);
    }

    deleteCompanyAnalytics(id) {
        return this.request("delete", `/api/company-analytics/${id}/`);
    }

    readAssetOperations() {
        return this.request("get", "/api/asset-operation/");
    }

    createAssetOperation(data) {
        return this.request("post", "/api/asset-operation/", data);
    }

    readSecondaryMarketSellOrderList() {
        return this.request("get", "/api/market/");
    }

    addSecondaryMarketSellOrder(id) {
        return this.request("post", `/api/market/${id}/add_order/`);
    }

    readMySecondaryMarketSellOrderList() {
        return this.request("get", "/api/secondaryMarketSellOrder/");
    }

    readMySecondaryMarketBuyOrderList() {
        return this.request("get", "/api/tiny-secondaryMarketBuyOrder/");
    }

    cancelBuyOrder(id) {
        return this.request("post", `/api/secondaryMarketBuyOrder/${id}/cancel/`);
    }

    refuseBuyOrder(id) {
        return this.request("post", `/api/secondaryMarketBuyOrder/${id}/refuse/`);
    }

    readMyRelatedSecondaryMarketBuyOrderList() {
        return this.request("get", "/api/secondaryMarketBuyOrder/?sell=true");
    }

    getSecondaryMarketBuyOrderListBySellOrderId(val) {
        return this.request("get", "/api/secondaryMarketBuyOrder/?sellOrderId=" + val);
    }

    readSecondaryMarketSellOrder(id) {
        return this.request("get", `/api/secondaryMarketSellOrder/${id}/`);
    }

    readMarketOrder(id) {
        return this.request("get", `/api/market/${id}/`);
    }

    cancelSellOrder(id) {
        return this.request("post", `/api/secondaryMarketSellOrder/${id}/cancel/`, {});
    }

    readSecondaryMarketBuyOrder(id) {
        return this.request("get", `/api/secondaryMarketBuyOrder/${id}/`);
    }

    readRelatedSecondaryMarketBuyOrder(id) {
        return this.request("get", `/api/secondaryMarketBuyOrder/${id}/?sell=true`);
    }

    secondaryMarketSetupProcedureTemplate(id) {
        return this.request(
            "patch",
            `/api/secondaryMarketSellOrder/${id}/setup_procedure_template/`
        );
    }
    createAssetMovementBuyOrder(data) {
        return this.request("post", `/api/secondaryMarketSellOrder/${data.sellOrderId}/buy/`, data);
    }

    createSecondaryMarketSellOrder(data) {
        return this.request("post", `/api/secondaryMarketSellOrder/`, data);
    }

    getSecondaryMarketSellOrder(id) {
        return this.request("get", `/api/secondaryMarketSellOrder/${id}/`);
    }

    patchSecondaryMarketSellOrder(id, data) {
        return this.request("patch", `/api/secondaryMarketSellOrder/${id}/`, data);
    }

    getSellerFeesSecondaryMarket() {
        return this.request("get", "/api/fees/calculate_seller_fees_secondary_market/");
    }
    getBuyerFeesSecondaryMarket() {
        return this.request("get", "/api/fees/calculate_buyer_fees_secondary_market/");
    }
    // SECONDARY MARKET PREFERENCES (MEMBERS / DOCS)

    getSecondaryMarketPrefVideo(id) {
        return this.request("get", `/api/secondary-market-video/?entity_id=${id}`);
    }

    createSecondaryMarketPrefVideo(video) {
        return this.request("post", `/api/secondary-market-video/`, video);
    }

    deleteSecondaryMarketPrefVideo(id) {
        return this.request("delete", `/api/secondary-market-video/${id}/`);
    }

    getSecondaryMarketPrefDocs(id) {
        return this.request("get", `/api/secondary-market-document/?entity_id=${id}`);
    }

    createSecondaryMarketPrefDocs(data) {
        return this.request("post", "/api/secondary-market-document/", data);
    }

    updateSecondaryMarketPrefDocs(id, data) {
        return this.request("patch", `/api/secondary-market-document/${id}/`, data);
    }

    getSecondaryMarketPrefMembers(id) {
        return this.request("get", `/api/secondary-market-member/`, {
            id: id
        });
    }

    createSecondaryMarketPrefMembers(data) {
        return this.request("post", "/api/secondary-market-member/", data);
    }

    updateSecondaryMarketPrefMembers(id, data) {
        return this.request("patch", `/api/secondary-market-member/${id}/`, data);
    }

    deleteSecondaryMarketPrefMembers(id) {
        return this.request("delete", `/api/secondary-market-member/${id}/`);
    }

    getDataroom(operationId) {
        return this.request("get", "/api/dataroom/?operation_id=" + operationId);
    }

    getExchangeRate() {
        return this.request("get", "api/balance/exchange_rate/");
    }

    /* PREFERENCES */
    getPreferences() {
        return this.request("get", "api/preferences/");
    }
    updatePreferences(data) {
        /*
      DATA = {
        name: name_of_preference,
        value: value_of_preference,
      }
    */
        return this.request("patch", "api/preferences/" + data.name + "/", {
            value: data.value
        });
    }

    // FACTURATION (Subscribtion/company-rates)
    getFacturation() {
        return this.request("get", "api/facturation/");
    }
    updateFacturation(id, data) {
        return this.request("patch", `api/facturation/${id}/`, data);
    }
    addCoupon(id, data) {
        return this.request("patch", `api/facturation/${id}/addCoupon/`, data);
    }

    addSiege(id, data, calculation_mode, nb_siege, frequency, route_redirection) {
        return this.request(
            "patch",
            `api/facturation/${id}/add_siege/?calculation_mode=${calculation_mode}&nb_siege=${nb_siege}&frequency=${frequency}&route_redirection=${route_redirection}`,
            data
        );
    }

    payBill(id, data, route_redirection, card_id) {
        return this.request(
            "patch",
            `api/factures/${id}/pay/?route_redirection=${route_redirection}&card_id=${card_id}`,
            data
        );
    }

    factureSiege(id, data, calculation_mode, nb_siege, frequency, route_redirection) {
        return this.request(
            "patch",
            `api/facturation/${id}/generate_bill/?calculation_mode=${calculation_mode}&nb_siege=${nb_siege}&frequency=${frequency}&route_redirection=${route_redirection}`,
            data
        );
    }

    removeSubscription(id, data) {
        return this.request("patch", `api/facturation/${id}/unsubscribe/`, data);
    }

    subscribe(id, data, calculation_mode, frequency, route_redirection) {
        return this.request(
            "patch",
            `api/facturation/${id}/subscribe/?calculation_mode=${calculation_mode}&frequency=${frequency}&route_redirection=${route_redirection}`,
            data
        );
    }

    addcard(id, data, calculation_mode, route_redirection) {
        return this.request(
            "patch",
            `api/facturation/${id}/add_card/?calculation_mode=${calculation_mode}&route_redirection=${route_redirection}`,
            data
        );
    }

    change_forfait(id, data, calculation_mode, frequency, route_redirection) {
        return this.request(
            "patch",
            `api/facturation/${id}/change_forfait/?calculation_mode=${calculation_mode}&frequency=${frequency}&route_redirection=${route_redirection}`,
            data
        );
    }

    updateWelcomeCoupon(id, data) {
        return this.request("patch", `api/facturation/${id}/updateWelcomeCoupon/`, data);
    }

    deleteCard(id, cardId) {
        let data = {
            type: "card",
            id: cardId
        };
        return this.request("delete", `api/facturation/${id}/`, data);
    }
}
